import React from "react"
import { Link } from 'gatsby'

import Layout from "~components/layout/layout.component"
import SEO from "~components/seo/seo.component"

const UnsubscribePage = () => (
    <Layout>
      <SEO title="Terms and Conditions" />
      <h1>How To Unsubscribe</h1>
      <p>TBD</p>
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )

export default UnsubscribePage
